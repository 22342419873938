import React, { useState, useEffect } from 'react';
import PitchFinder from 'pitchfinder';

function Tuner() {
    const [detectedNote, setDetectedNote] = useState({ note: '♪', frequency: 0, frequencyError: 0 });

    const [volumeThreshold, setVolumeThreshold] = useState(0.02); // Default threshold
    const [currentDb, setCurrentDb] = useState(0);
    const [stabilityTimer, setStabilityTimer] = useState(null);
    const [circleFillPercentage, setCircleFillPercentage] = useState(0);

    useEffect(() => {
        const detectPitch = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
                const audioContext = new (window.AudioContext || window.webkitAudioContext)();
                const source = audioContext.createMediaStreamSource(stream);
                const analyser = audioContext.createAnalyser();
                source.connect(analyser);
                analyser.fftSize = 2048;
                const bufferLength = analyser.frequencyBinCount;
                const dataArray = new Float32Array(bufferLength);
                // Inside your detect function before the volume threshold check
                const rms = Math.sqrt(dataArray.reduce((acc, val) => acc + val * val, 0) / dataArray.length);
                const db = 20 * Math.log10(rms);
                setCurrentDb(db);

                const detect = () => {
                    analyser.getFloatTimeDomainData(dataArray);
                    const volumeThreshold = 0.02; // Adjust based on your needs
                    const currentVolume = dataArray.reduce((acc, val) => acc + Math.abs(val), 0) / dataArray.length;
                    const rms = Math.sqrt(dataArray.reduce((acc, val) => acc + val * val, 0) / dataArray.length);
                    const db = 20 * Math.log10(rms);
                    setCurrentDb(db); 
                    if (currentVolume > volumeThreshold) {
    const pitchDetector = PitchFinder.YIN();
    const frequency = pitchDetector(dataArray);
    if (frequency) {
        const noteInfo = frequencyToNoteName(frequency);
        // Check if the note is within a small margin of error
        if (Math.abs(noteInfo.errorInCents) <= 10) {
            if (!stabilityTimer) {
                // Start stability timer if not already started
                const timer = setTimeout(() => {
                    setCircleFillPercentage(100); // Fill circle after 10 seconds of stability
                }, 10000); // 10 seconds
                setStabilityTimer(timer);
            }
        } else {
            // Reset if note is outside the margin
            clearTimeout(stabilityTimer);
            setStabilityTimer(null);
            setCircleFillPercentage(0);
        }
        setDetectedNote(noteInfo);
    } else {
        // Handle no detection
        clearTimeout(stabilityTimer);
        setStabilityTimer(null);
        setCircleFillPercentage(0);
        setDetectedNote({ note: '', frequency: 0 });
    }
} else {
    // Handle silence
    clearTimeout(stabilityTimer);
    setStabilityTimer(null);
    setCircleFillPercentage(0);
    setDetectedNote({ note: '♪', frequency: 0 });
}

                    requestAnimationFrame(detect);

                };

                detect();
            } catch (error) {
                console.error('Error accessing the microphone', error);
                setDetectedNote({ note: 'Error accessing the microphone', frequency: 0 });
            }
        };

        detectPitch();
    }, []);

    // This function converts frequency to the closest musical note name and returns both note and frequency.
   function frequencyToNoteName(frequency) {
    const noteStrings = ['C', 'C#', 'D', 'D#', 'E', 'F', 'F#', 'G', 'G#', 'A', 'A#', 'B'];
    const c0 = 16.35; // Frequency of C0
    const halfStepsAboveC0 = 12 * Math.log2(frequency / c0);
    const noteNumber = Math.round(halfStepsAboveC0);
    const octave = Math.floor(noteNumber / 12);
    const noteIndex = noteNumber % 12;
    const noteName = noteStrings[noteIndex];
    const exactFrequency = c0 * Math.pow(2, noteNumber / 12);

    // Calculate the frequencies of adjacent notes
    const nextNoteFrequency = c0 * Math.pow(2, (noteNumber + 1) / 12);
    const prevNoteFrequency = c0 * Math.pow(2, (noteNumber - 1) / 12);

    // Determine the midpoint frequencies
    const nextMidpoint = (exactFrequency + nextNoteFrequency) / 2;
    const prevMidpoint = (exactFrequency + prevNoteFrequency) / 2;

    // Determine if the frequency is closer to the current note or the adjacent ones
    let tuningIndicator = '0'; // Assume in tune ('0') by default
    if (frequency > exactFrequency && frequency < nextMidpoint) {
        tuningIndicator = '#'; // Sharper than the exact frequency but still closer to the current note
    } else if (frequency < exactFrequency && frequency > prevMidpoint) {
        tuningIndicator = '♭'; // Flatter than the exact frequency but still closer to the current note
    }

    const errorInCents = 1200 * Math.log2(frequency / exactFrequency);

    return { note: `${noteName}${octave}`, frequency, errorInCents, tuningIndicator };
}
const calculateFillHeight = (errorInCents) => {
    // Assuming errorInCents can range from -50 (flat) to +50 (sharp)
    const maxError = 50;
    // Convert error to a percentage of the maxError, capped at 100%
    let fillPercentage = (Math.abs(errorInCents) / maxError) * 100;
    return fillPercentage > 100 ? 100 : fillPercentage; // Ensure the fill doesn't exceed 100%
};


function errorToPercentage(error, maxError = 50) {
    const clampedError = Math.max(-maxError, Math.min(maxError, error)); // Use errorInCents here
    return (clampedError / maxError) * 100; // Convert error to percentage of maxError
}



const mapDbToVisualScale = (db) => {
      const minDb = -60; // Minimum dBFS value you want to display
      const maxDb = 0; // Maximum dBFS value (0 dBFS)
      return ((db - minDb) / (maxDb - minDb)) * 100;
    };

    return (
    <div className="tuner">
        {/*<div className="controls">
            <label>
                Volume Threshold: {volumeThreshold.toFixed(2)}
                <input
                    type="range"
                    min="0"
                    max="1.6"
                    step="0.01"
                    value={volumeThreshold}
                    onChange={(e) => setVolumeThreshold(parseFloat(e.target.value))}
                />
            </label>
            
        </div>
        <div className="db-meter">
            <div className="db-meter-fill" style={{width: `${mapDbToVisualScale(currentDb)}%`}}></div>
        </div>*/}
        <div className="tunerplusindi">
        <div className="tuning-accuracy-indicator" style={{ borderRadius: '6px' , overflow: 'hidden' , position: 'relative', height: '220px', width: '20px', border: '0px solid #EA6518' }}>
        <div className="tuning-accuracy-fill" style={{
    position: 'absolute',
    width: '100%',
    backgroundColor: '#EA6518',
    height: detectedNote.frequency > 0 && Math.abs(currentDb) > volumeThreshold ? `${calculateFillHeight(detectedNote.errorInCents)}%` : '0%',
    bottom: detectedNote.errorInCents >= 0 ? '50%' : 'auto',
    top: detectedNote.errorInCents < 0 ? '50%' : 'auto'
}}></div>


<div className="tuning-center-line" style={{ position: 'absolute', left: '0', bottom: '50%', width: '100%', height: '5px', backgroundColor: '#9DBF9E ' }}></div>
        </div>

        <div className="note-indicator" >
          <p className="note">{detectedNote.note}{detectedNote.tuningIndicator}</p>
          <p>{detectedNote.frequency > 0 ? `${detectedNote.frequency.toFixed(2)} Hz` : ''}</p>
          </div>
        </div>

        
    </div>
    
    
);

}

export default Tuner;
