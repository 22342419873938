import React, { useState, useEffect } from 'react';
import Select from 'react-select';

function Metronome() {
  const [bpm, setBpm] = useState(60);
  const [isPlaying, setIsPlaying] = useState(false);
  const [audioContext, setAudioContext] = useState(null);
  const [clickBuffer, setClickBuffer] = useState(null);
  const [accentedBeats, setAccentedBeats] = useState([]);
  const [accentedClickBuffer, setAccentedClickBuffer] = useState(null);
  const [currentBeat, setCurrentBeat] = useState(1);
  const [beatsPerMeasure, setBeatsPerMeasure] = useState(4);
  const [noteValue, setNoteValue] = useState(4);
  const [maxBpm, setMaxBpm] = useState(60);
  const [bpmIncrease, setBpmIncrease] = useState(10);
  const [barsForIncrease, setBarsForIncrease] = useState(4);
  const [barCounter, setBarCounter] = useState(0);
   const resetSettings = () => {
    setBpm(60);
    setIsPlaying(false);
    setBeatsPerMeasure(4);
    setNoteValue(4);
    setMaxBpm(60);
    setBpmIncrease(10);
    setBarsForIncrease(4);
    setBarCounter(1);
    setAccentedBeats([]);
    setIsAscending(true);
    // You can also reset other states if necessary
  };
  const [isLooping, setIsLooping] = useState(false);
  const [isAscending, setIsAscending] = useState(true);
  const [showAdvancedOptions, setShowAdvancedOptions] = useState(false);

// Calculate the fraction of the progress
const progressFraction = (barCounter % barsForIncrease) / barsForIncrease;

// Calculate the new strokeDashoffset based on progress
const newDashoffset = 565.48 * (1 - progressFraction);

// Set this value to your progress circle's strokeDashoffset dynamically

  useEffect(() => {
    const context = new AudioContext();
    setAudioContext(context);

    fetch('/sound/click-sound.mp3')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
      .then(audioBuffer => setClickBuffer(audioBuffer));

    fetch('/sound/accented-click-sound.mp3')
      .then(response => response.arrayBuffer())
      .then(arrayBuffer => context.decodeAudioData(arrayBuffer))
      .then(audioBuffer => setAccentedClickBuffer(audioBuffer));

    return () => context.close();
  }, []);
  const options = [...Array(beatsPerMeasure)].map((_, i) => ({ value: i + 1, label: (i + 1).toString() }));

  const handleSelectChange = selectedOptions => {
    setAccentedBeats(selectedOptions.map(option => option.value));
  };
  const playClick = () => {
    if (audioContext) {
      const source = audioContext.createBufferSource();
      source.buffer = accentedBeats.includes(currentBeat) ? accentedClickBuffer : clickBuffer;
      source.connect(audioContext.destination);
      source.start();

      if (currentBeat === 1) {
        if (isAscending) {
          if (barCounter >= barsForIncrease && bpm < maxBpm) {
            setBpm(bpm + bpmIncrease);
          } else if (barCounter >= barsForIncrease && bpm === maxBpm && isLooping) {
            setIsAscending(false);
          }
        } else {
          if (barCounter >= barsForIncrease && bpm > 60) { // Assuming 60 is the initial BPM
            setBpm(bpm - bpmIncrease);
          } else if (barCounter >= barsForIncrease && bpm === 60 && isLooping) {
            setIsAscending(true);
          }
        }

        setBarCounter(barCounter < barsForIncrease ? barCounter + 1 : 1);
      }

      setCurrentBeat(currentBeat < beatsPerMeasure ? currentBeat + 1 : 1);
    }
  };

  useEffect(() => {
    let timer;
    if (isPlaying) {
      timer = setInterval(playClick, (60 / bpm) * 1000);
    } else {
      clearInterval(timer);
    }
    return () => clearInterval(timer);
  }, [isPlaying, bpm, audioContext, clickBuffer, accentedClickBuffer, currentBeat, beatsPerMeasure]);
// New useEffect for synchronizing max BPM
useEffect(() => {
  if (!showAdvancedOptions) {
    setMaxBpm(bpm);
  }
}, [showAdvancedOptions, bpm]);
  return (
    <div className="metronome-container">

        <div className="settings-container">
            <div className="startbutton">
  <button onClick={() => setIsPlaying(!isPlaying)} style={{ background: 'none', border: 'none', cursor: 'pointer' }}>
    {isPlaying ? (
      <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#fff" />
        <path d="M8 5H11V19H8V5ZM13 5H16V19H13V5Z" fill="#EA6518" />
      </svg>
    ) : (
      <svg width="50" height="50" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle cx="12" cy="12" r="12" fill="#fff" />
        <path d="M8 5V19L19 12L8 5Z" fill="purple" />
      </svg>
    )}
  </button>
</div>

     
      <div className="options">
      <div className="label">Tempo</div>
      <div className="input-container">
      
      <input
  type="number"
  min="40"
  max="240"
  value={bpm}
  onChange={(e) => {
    const newValue = Number(e.target.value);
    setBpm(newValue >= 1 ? newValue : bpm); // Prevent setting bpm below 40 during onChange
  }}
  onBlur={(e) => {
    if (bpm < 1) {
      setBpm(40); // Correct to 40 if value is below 40 when input loses focus
    } else if (bpm > 240) {
      setBpm(240); // Correct to 240 if value is above 240 when input loses focus
    }
  }}
  className="labeled input bpm-input"
/>
<span className="text-label">BPM</span>
      </div>
      {showAdvancedOptions && (
        <>
            <div className="label">Max Tempo</div>
            <div className="input-container">
      
          <input className="input labeled" type="number" min="40" max="240" value={maxBpm} onChange={(e) => setMaxBpm(Number(e.target.value))} />
          <span className="text-label">BPM</span>
          </div>
          <div className="label">Increase Tempo By</div>
          <div className="input-container">
      
          <input className="input labeled" type="number" min="1" value={bpmIncrease} onChange={(e) => setBpmIncrease(Number(e.target.value))} />
          <span className="text-label">BPM</span>
          </div>
          <div className="label">After every </div>
          <div className="input-container">
      
          <input className="input labeled" type="number" min="1" value={barsForIncrease} onChange={(e) => setBarsForIncrease(Number(e.target.value))} />
          <span className="text-label">BARS</span>
        </div>
        </>
      )}
      
      </div>
      <div className="time-sig">
        <div className="label">Time Signature </div>
            <div className="sig-options"> 
        <select value={beatsPerMeasure} onChange={(e) => setBeatsPerMeasure(Number(e.target.value))}>
          {[...Array(16)].map((_, i) => <option key={i+1} value={i+1}>{i+1}</option>)}
        </select>
        <span className="sepa">/</span>
        <select value={noteValue} onChange={(e) => setNoteValue(Number(e.target.value))}>
          {[2, 4, 8, 16].map(value => <option key={value} value={value}>{value}</option>)}
        </select>
      </div>
      </div>
       <div className="accent">
       
          <div className="label">Accented Beats </div>
          <Select
            options={options}
            isMulti
            onChange={handleSelectChange}
            value={options.filter(option => accentedBeats.includes(option.value))}
            placeholder="Choose..."
          />
        
      </div>
    </div>
    <div className="graph-container">
    
<circle className="progress-circle" cx="100" cy="100" r="90" stroke="#800080" strokeWidth="20" fill="transparent" strokeDasharray="565.48" strokeDashoffset={newDashoffset.toString()} transform="rotate(-90 100 100)" />

        <div className="graph-circle">
    <svg width="200" height="200" viewBox="0 0 200 200">
        <circle cx="100" cy="100" r="90" stroke="#EA6518" strokeWidth="20" fill="transparent" />
        
        <circle className="progress-circle" cx="100" cy="100" r="90" stroke="#800080" strokeWidth="20" fill="transparent" strokeDasharray="565.48" strokeDashoffset={newDashoffset.toString()} transform="rotate(-90 100 100)" />
<circle className="progress-circle" cx="100" cy="100" r="90" stroke="#800080" strokeWidth="20" fill="transparent" strokeDasharray="565.48" strokeDashoffset="565.48" transform="rotate(-90 100 100)" />
    </svg>
    <div className="abso-info">
    <div className="current-bpm">{bpm}</div>
    <div className="barcounter">
        Counter: {barCounter}/{barsForIncrease}
    </div>
    </div>
</div>

            <div className="setting-row advanced-options">
                <span>Advanced Options:</span>
                <input type="checkbox" checked={showAdvancedOptions} onChange={() => setShowAdvancedOptions(!showAdvancedOptions)}/>
            </div>
            <div className="setting-row looping">
                <span>Looping:</span>
                <input type="checkbox" checked={isLooping} onChange={() => setIsLooping(!isLooping)}/>
            </div>
            <button className="reset" onClick={() => window.location.reload()}>Reset</button>
            
        </div>
    </div>
  );
}

export default Metronome;
