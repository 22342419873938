import React, { useState } from 'react';
import './App.css';
import Metronome from './Metronome';
import Tuner from './Tuner';

function App() {
  const [activeTab, setActiveTab] = useState('metronome'); // 'metronome' or 'tuner'

  return (
    <div className="App">
      
      <div className="tabs">
      
        <button 
  className={`pushable ${activeTab === 'metronome' ? 'active' : ''}`}
  onClick={() => setActiveTab('metronome')}
>
  <span className="front">
    Metronome
  </span>
</button>

        <button className={`pushable ${activeTab === 'tuner' ? 'active' : ''}`} onClick={() => setActiveTab('tuner')}>
          <span className="front">
    Tuner <span className="beta">(beta)</span>
  </span>
        </button>
      </div>
      <main>
        {activeTab === 'metronome' && <Metronome />}
        {activeTab === 'tuner' && <Tuner />}
      </main>
     
    </div>
  );
}


export default App;